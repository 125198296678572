$current-theme-name: "custom";
$color-scheme: #00a87e;

@import '../init';

/*----------- Table of Contents -----------*/

/**
 * Globals
 * Preloader
 * Sidebar
 * Home
 * About
 * Services
 * Hire
 * Portfolio
 * Testimonials
 * Blog
 * Contact
 * Footer
 */

@import 'partials/_globals';
@import 'partials/_preloader';
@import 'partials/_sidebar';
@import 'partials/_home';
@import 'partials/_about';
@import 'partials/_services';
@import 'partials/_hire';
@import 'partials/_portfolio';
@import 'partials/_testimonials';
@import 'partials/_blog';
@import 'partials/_contact';
@import 'partials/_footer';
