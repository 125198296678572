/*----------- Sidebar -----------*/

.sidebar {
  background-color: #111;

  .sidebar-header {
    padding-top: 24px;
    padding-bottom: 60px;
    text-align: center;
    .sidebar-avatar {
      width: 110px;
      border-radius: 50%;
      border: 5px solid #484848;
      margin-bottom: 16px;
    }
    .sidebar-name {
      display: block;
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 8px;
    }
    .sidebar-status {
      color: #8a8a8a;
      line-height: 100%;
      margin-top: 9px;
      display: block;
      margin-bottom: 0;
    }
  }

  .sidebar-menu {
    padding-bottom: 24px;
    .list-menu {
      margin-bottom: 0;
      text-align: center;
      li {
        line-height: 100%;
        &:not(:last-child) {
          margin-bottom: 28px;
        }
      }

      .nav-link {
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 100%;
        padding: 0;
        color: rgba(#e8e8e8, .95);
      }
    }
  }

  .simplebar-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .simplebar-track {
    .simplebar-scrollbar {
      &:before {
        background: #fff;
      }

      &.simplebar-visible:before {
        opacity: 0.5;
      }
    }

    &.simplebar-vertical {
      width: 10px;

      .simplebar-scrollbar {
        width: 6px;
        height: auto;
      }
    }

    &.simplebar-horizontal {
      height: 10px;

      .simplebar-scrollbar {
        height: 6px;
        width: auto;
      }
    }
  }
}

.mobile-navbar {
  padding: 14px 26px;
  &:before {
    content: '';
    background-color: #222;
    width: 100%;
    height: 58px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .navbar-toggler {
    border-color: #222;
    background-color: #333;
    outline: none;
    padding: 4px 10px;
    font-size: 14px;
    border-radius: 3px;
    position: relative;
    display: block;

    .nav-btn {
      font-size: 14px;
      color: rgba(#fff, 0.8);
      line-height: 20px;
      font-weight: 300;
      letter-spacing: 1px;
    }
  }

  .navbar-collapse .navbar-nav {
    width: 200px;
    background-color: #222;
    z-index: 2;
    position: relative;
    padding: 8px 20px;
    top: 8px;
    .nav-link {
      color: rgba(#fff, 0.60);
      font-weight: 400;
      font-size: 14px;
      padding: 7px 0;
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
}
/*----------- Sidebar: Colors -----------*/

.sidebar {
  .sidebar-menu {
    .list-menu {
      .nav-link {
        &.active {
          color: $color-scheme;
        }
      }
    }
  }
}
